import { BuyerMethods } from './Buyer'
import type { GetAwayPurchaseDTO } from "./GetAwayPurchase"

export class GetAwayPurchaseBuyer {
  constructor(
    public name: string,
    public surname: string,
    public phone: string,
    public email: string,
    public comment: string,
    public allowNotifications: boolean,
    public location: {
      address: string,
      country: string,
      city: string,
      postalCode: string,
    },
  ) {}

  public static createEmpty(): GetAwayPurchaseBuyer {
    return new GetAwayPurchaseBuyer(
      '', '',
      '',
      '',
      '',
      false,
      {
        address: '',
        city: '',
        country: '',
        postalCode: '',
      }
    )
  }

  public static copy(purchase: GetAwayPurchaseBuyer): GetAwayPurchaseBuyer {
    return new GetAwayPurchaseBuyer(
      purchase.name, purchase.surname,
      purchase.phone,
      purchase.email,
      purchase.comment,
      purchase.allowNotifications,
      purchase.location,
    )
  }

  public static fromGetAwayBuyer(dto: GetAwayPurchaseDTO['buyer']): GetAwayPurchaseBuyer {
    const location = {
      address: dto.localization.address,
      country: dto.localization.country,
      city: dto.localization.city,
      postalCode: dto.localization.postal_code,
    }

    return new GetAwayPurchaseBuyer(
      dto.name,
      dto.surname,
      dto.phone,
      dto.email,
      dto.comment,
      dto.allow_notifications,
      location,
    )
  }

  public static toGetAwayBuyer(dto: GetAwayPurchaseBuyer): GetAwayPurchaseDTO['buyer'] {
    return {
      name: dto.name,
      surname: dto.surname,
      phone: dto.phone,
      email: dto.email,
      comment: dto.comment,
      allow_notifications: dto.allowNotifications,
      localization: {
        address: dto.location.address,
        country: dto.location.country,
        city: dto.location.city,
        postal_code: dto.location.postalCode,
      },
    }
  }

  static getValidationErrors(buyer: GetAwayPurchaseBuyer) {
    const validators = this.getValidationDict()

    return Object.entries(buyer).map(([key, value]) => {
      const validator = validators[key as keyof GetAwayPurchaseBuyer]
      if (!validator) {
        return { key, isValid: true }
      }

      const isValid = validator(value)

      return { key, isValid }
    })
  }

  static getFilteredValidationErrors(buyer: GetAwayPurchaseBuyer) {
    return this.getValidationErrors(buyer).filter(({ isValid }) => !isValid)
  }

  static isAllValid(buyer: GetAwayPurchaseBuyer): boolean {
    const errors = this.getValidationErrors(buyer)
    return errors.every(({ isValid }) => isValid)
  }

  static getValidationDict(): Record<string, (value: any) => boolean> {
    return {
      name: BuyerMethods.validate.name,
      surname: BuyerMethods.validate.surname,
      email: BuyerMethods.validate.email,
      phone: BuyerMethods.validate.requiredPhone,
    }
  }
}

<template>
  <div>
    <Notification
      :title="notifTitle"
      :content="notifContent"
      :is-displayed="display"
      is-accept-button-displayed
      @on-accept="hide"
    />
  </div>
</template>


<script lang="ts">
import Notification from '../shared/Notification.vue'

import NotificationEventBus from '~/core/events/notification'

export default defineNuxtComponent({
  components: {
    Notification,
  },
  setup() {
    const display = ref(false)

    const notifTitle = ref('')
    const notifContent = ref('')

    let id: null | number = null
    onBeforeMount(() => {
      id = NotificationEventBus.on((title: string, message: string) => {
        display.value = true
        notifTitle.value = title
        notifContent.value = message
      })
    })

    onBeforeUnmount(() => {
      if (id) {
        NotificationEventBus.off(id)
      }
    })

    function hide() {
      display.value = false
    }

    return {
      display,
      notifTitle,
      notifContent,
      hide,
    }
  }
})
</script>

import { default as buyer3jeA8aTVZFMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/buyer.vue?macro=true";
import { default as paymentSpWsqu0YCKMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/payment.vue?macro=true";
import { default as confirmationPGVb9PJHrHMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/confirmation.vue?macro=true";
import { default as koQVLNBEV34jMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/ko.vue?macro=true";
import { default as startdIpgxHRpa5Meta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as buyerGorzNdTIIWMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/buyer.vue?macro=true";
import { default as paymentx9KHnX1EkGMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/payment.vue?macro=true";
import { default as confirmationoSJtJ4PfLoMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/confirmation.vue?macro=true";
import { default as challengetSiJYevzDwMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/redsys/challenge.vue?macro=true";
import { default as startupy3wjSjM1Meta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as indexXYCk4u98voMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/index.vue?macro=true";
import { default as indexk0v6qNZChuMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/pt/index.vue?macro=true";
import { default as checkoutUo7wK6rwjbMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/service-booking/checkout.vue?macro=true";
import { default as SpaList_46pageeTMWlatsPVMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pageiEDo6JCLboMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pagesaEGwwkvcdMeta } from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: buyer3jeA8aTVZFMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyer3jeA8aTVZFMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    meta: buyer3jeA8aTVZFMeta || {},
    alias: buyer3jeA8aTVZFMeta?.alias || [],
    redirect: buyer3jeA8aTVZFMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/buyer.vue").then(m => m.default || m)
  },
  {
    name: paymentSpWsqu0YCKMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentSpWsqu0YCKMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    meta: paymentSpWsqu0YCKMeta || {},
    alias: paymentSpWsqu0YCKMeta?.alias || [],
    redirect: paymentSpWsqu0YCKMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: confirmationPGVb9PJHrHMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationPGVb9PJHrHMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    meta: confirmationPGVb9PJHrHMeta || {},
    alias: confirmationPGVb9PJHrHMeta?.alias || [],
    redirect: confirmationPGVb9PJHrHMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: koQVLNBEV34jMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koQVLNBEV34jMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    meta: koQVLNBEV34jMeta || {},
    alias: koQVLNBEV34jMeta?.alias || [],
    redirect: koQVLNBEV34jMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/ko.vue").then(m => m.default || m)
  },
  {
    name: startdIpgxHRpa5Meta?.name ?? "lang-purchase-start",
    path: startdIpgxHRpa5Meta?.path ?? "/:lang?/purchase/start",
    meta: startdIpgxHRpa5Meta || {},
    alias: startdIpgxHRpa5Meta?.alias || [],
    redirect: startdIpgxHRpa5Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/purchase/start.vue").then(m => m.default || m)
  },
  {
    name: buyerGorzNdTIIWMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerGorzNdTIIWMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    meta: buyerGorzNdTIIWMeta || {},
    alias: buyerGorzNdTIIWMeta?.alias || [],
    redirect: buyerGorzNdTIIWMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/buyer.vue").then(m => m.default || m)
  },
  {
    name: paymentx9KHnX1EkGMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentx9KHnX1EkGMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    meta: paymentx9KHnX1EkGMeta || {},
    alias: paymentx9KHnX1EkGMeta?.alias || [],
    redirect: paymentx9KHnX1EkGMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: confirmationoSJtJ4PfLoMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationoSJtJ4PfLoMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    meta: confirmationoSJtJ4PfLoMeta || {},
    alias: confirmationoSJtJ4PfLoMeta?.alias || [],
    redirect: confirmationoSJtJ4PfLoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: challengetSiJYevzDwMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengetSiJYevzDwMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    meta: challengetSiJYevzDwMeta || {},
    alias: challengetSiJYevzDwMeta?.alias || [],
    redirect: challengetSiJYevzDwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/redsys/challenge.vue").then(m => m.default || m)
  },
  {
    name: startupy3wjSjM1Meta?.name ?? "lang-voucher-start",
    path: startupy3wjSjM1Meta?.path ?? "/:lang?/voucher/start",
    meta: startupy3wjSjM1Meta || {},
    alias: startupy3wjSjM1Meta?.alias || [],
    redirect: startupy3wjSjM1Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/[[lang]]/voucher/start.vue").then(m => m.default || m)
  },
  {
    name: indexXYCk4u98voMeta?.name ?? "index",
    path: indexXYCk4u98voMeta?.path ?? "/",
    meta: indexXYCk4u98voMeta || {},
    alias: indexXYCk4u98voMeta?.alias || [],
    redirect: indexXYCk4u98voMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexk0v6qNZChuMeta?.name ?? "pt",
    path: indexk0v6qNZChuMeta?.path ?? "/pt",
    meta: indexk0v6qNZChuMeta || {},
    alias: indexk0v6qNZChuMeta?.alias || [],
    redirect: indexk0v6qNZChuMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/pt/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutUo7wK6rwjbMeta?.name ?? "service-booking-checkout",
    path: checkoutUo7wK6rwjbMeta?.path ?? "/service-booking/checkout",
    meta: checkoutUo7wK6rwjbMeta || {},
    alias: checkoutUo7wK6rwjbMeta?.alias || [],
    redirect: checkoutUo7wK6rwjbMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/pages/service-booking/checkout.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/aguilas/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/archena/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/cartagena/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/la-manga-del-mar-menor/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/los-alcazares/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/murcia/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/puerto-lumbreras/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/murcia/torre-pacheco/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaList_46pageeTMWlatsPVMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageeTMWlatsPVMeta?.path ?? "/spa-balneario/region-de-murcia/",
    meta: SpaList_46pageeTMWlatsPVMeta || {},
    alias: SpaList_46pageeTMWlatsPVMeta?.alias || [],
    redirect: SpaList_46pageeTMWlatsPVMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaList.page.vue").then(m => m.default || m)
  },
  {
    name: SpaDetail_46pageiEDo6JCLboMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pageiEDo6JCLboMeta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/",
    meta: SpaDetail_46pageiEDo6JCLboMeta || {},
    alias: SpaDetail_46pageiEDo6JCLboMeta?.alias || [],
    redirect: SpaDetail_46pageiEDo6JCLboMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaDetail.page.vue").then(m => m.default || m)
  },
  {
    name: SpaDetail_46pageiEDo6JCLboMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pageiEDo6JCLboMeta?.path ?? "/spa-balneario/murcia/poseidon-la-manga-hotel-spa/",
    meta: SpaDetail_46pageiEDo6JCLboMeta || {},
    alias: SpaDetail_46pageiEDo6JCLboMeta?.alias || [],
    redirect: SpaDetail_46pageiEDo6JCLboMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaDetail.page.vue").then(m => m.default || m)
  },
  {
    name: SpaDetail_46pageiEDo6JCLboMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pageiEDo6JCLboMeta?.path ?? "/spa-balneario/murcia/senator-mar-menor/",
    meta: SpaDetail_46pageiEDo6JCLboMeta || {},
    alias: SpaDetail_46pageiEDo6JCLboMeta?.alias || [],
    redirect: SpaDetail_46pageiEDo6JCLboMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaDetail.page.vue").then(m => m.default || m)
  },
  {
    name: SpaDetail_46pageiEDo6JCLboMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pageiEDo6JCLboMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/",
    meta: SpaDetail_46pageiEDo6JCLboMeta || {},
    alias: SpaDetail_46pageiEDo6JCLboMeta?.alias || [],
    redirect: SpaDetail_46pageiEDo6JCLboMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaDetail.page.vue").then(m => m.default || m)
  },
  {
    name: SpaDetail_46pageiEDo6JCLboMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pageiEDo6JCLboMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/",
    meta: SpaDetail_46pageiEDo6JCLboMeta || {},
    alias: SpaDetail_46pageiEDo6JCLboMeta?.alias || [],
    redirect: SpaDetail_46pageiEDo6JCLboMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/spa/SpaDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/spa-privado-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/masaje-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/spa-privado-masaje-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/spa-privado-cena-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/poseidon-la-manga-hotel-spa/circuito-spa/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/poseidon-la-manga-hotel-spa/circuito-spa-almuerzo-o-cena/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/senator-mar-menor/ritual-esplendor-radiante-circuito-spa/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/flotarium-ritual-termal-marino-estrella-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/masaje-hot-stone/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/ducha-vichy-masaje-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/flotarium-masaje-de-40-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/hidratacion-intensa-facial/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/ritual-termal-estrella/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/ritual-termal-marino/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/flotarium-ritual-termal-marino-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/flotarium/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/masaje-corporal/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/flotarium-privado-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/flotarium-masaje-de-60-para-2/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/fran-barahona-spa/masaje-antiestres/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/masaje-aromatico-energetico-55/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/masaje-aromatico-energetico-30/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/power-to-myself/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/luz-serena/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/masaje-lomi-lomi/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/brisa-del-amanecer/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/capricho-de-la-naturaleza/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/pure-detox/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/oasis-de-la-serenidad/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/ritual-reina-de-egipto/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/total-hidratacion-facial/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/masaje-relajante-con-aceites-aromaticos/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/armonia-zonal/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/circuito-spa/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  },
  {
    name: ServiceDetail_46pagesaEGwwkvcdMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pagesaEGwwkvcdMeta?.path ?? "/spa-balneario/murcia/spa-spaxion-la-torre/especial-embarazadas/",
    meta: ServiceDetail_46pagesaEGwwkvcdMeta || {},
    alias: ServiceDetail_46pagesaEGwwkvcdMeta?.alias || [],
    redirect: ServiceDetail_46pagesaEGwwkvcdMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/core/pages/service/ServiceDetail.page.vue").then(m => m.default || m)
  }
]
<template>
  <div />
</template>

<script lang="ts">
export default defineNuxtComponent({
  setup() {
    const config = useRuntimeConfig()
    const lang   = config.public.language
    const langToOct8ne: Record<string, string> = {
      es: 'es-ES',
      pt: 'pt-PT',
    }

    function getLocaleCode(): string {
      const locale = langToOct8ne[lang]
      if (!locale) {
        return 'es-ES'
      }

      return locale
    }

    function installOct8ne() {
      const win: any = window
      var oct8ne: any = document.createElement("script")

      oct8ne.id = 'oct8ne-chat-script'
      oct8ne.type = "text/javascript"
      oct8ne.server = "backoffice-eu.oct8ne.com/"
      oct8ne.async = true
      oct8ne.license = "69EE3D189D6E2B9D51ADB6B6C36C10BE"
      oct8ne.src = (document.location.protocol == "https:" ? "https://" : "http://") + "static-eu.oct8ne.com/api/v2/oct8ne-api-2.3.js?" + (Math.round(new Date().getTime() / 86400000))
      oct8ne.locale = getLocaleCode()
      oct8ne.baseUrl = "//www.spalopia.com/"

      win.oct8ne = oct8ne
      document.body.appendChild(oct8ne)

      var s = document.getElementById('oct8ne-chat-script') as HTMLScriptElement
      if (document.cookie.indexOf("oct8ne-room") == -1) {
        setTimeout(insertOct8ne, 5000)
        win.addEventListener("mousemove", insertOct8ne)
        win.addEventListener("scroll", insertOct8ne)
        win.addEventListener("click", insertOct8ne)
        win.addEventListener("keydown", insertOct8ne)
        win.addEventListener("touchstart", insertOct8ne)
      } else {
        insertOct8ne()
      } function insertOct8ne() {
        if (!win.oct8neScriptInserted) {
          (s.parentNode as HTMLScriptElement).insertBefore(oct8ne, s)
          win.oct8neScriptInserted = true
          win.removeEventListener("mousemove", insertOct8ne)
          win.removeEventListener("scroll", insertOct8ne)
          win.removeEventListener("click", insertOct8ne)
          win.removeEventListener("keydown", insertOct8ne)
          win.removeEventListener("touchstart", insertOct8ne)
        }
      }
    }

    onMounted(() => { setTimeout(() => installOct8ne(), 100) })
  }
})
</script>

<template>
  <section
    class="loading-container"
    :class="{ 'loading-container--displayed': isDisplayed }"
  >
    <span class="loading">
      <span class="loading__bubble" />
      <span class="loading__bubble" />
      <span class="loading__bubble" />
    </span>
  </section>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    isDisplayed: { type: Boolean, required: true },
  }
})
</script>

<style lang="scss" scoped>
.loading-container {
  @apply fixed;
  @apply top-0 left-0;
  @apply bg-black;
  @apply w-full h-full;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply opacity-0;
  @apply z-50;
  @apply invisible;

  -webkit-transition: all 500ms ease-in;
  -moz-transition: all 500ms ease-in;
  -ms-transition: all 500ms ease-in;
  -o-transition: all 500ms ease-in;
  transition: all 500ms ease-in;

  &--displayed {
    @apply visible;
    @apply opacity-75;
  }
}

.loading {
  @apply text-white;
  &__bubble {
    @apply w-2;
    @apply h-2;
    @apply bg-white;
    @apply rounded-full;
    @apply inline-block;
    @apply text-transparent;

    animation-name: dots;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    &:nth-child(1) {
      @apply bg-spl-primary-light;
    }
    &:nth-child(2) {
      @apply bg-spl-primary;
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      @apply bg-spl-primary-dark;
      animation-delay: 0.8s;
    }
  }
}

@keyframes dots {
  50% {
    @apply opacity-0;
    transform: scale(0.7) translateY(10px);
  }
}
</style>

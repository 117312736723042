export default class LoggerAPIRepository {
  static async sendInfo(name: string, meta: Record<string, string | object>): Promise<void> {
    const runtime = useRuntimeConfig()
    const url = `${runtime.public.apiBaseURL}/api/v1/log/info/ga4-analitics-${name}`

    const respone = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(meta)
    })

    if (!respone.ok) {
      throw new Error('Cannot publish log')
    }
  }
}

export default class EventBus {
  private static eventCache: Record<string, any[]> = {}
  private static autoId = 0

  static on(name: string, func: any): number {
    this.autoId++

    if (!this.eventCache[name]) {
      this.eventCache[name] = []
    }

    this.eventCache[name].push({
      id: this.autoId,
      func,
    })

    return this.autoId
  }

  static off(name: string, id: number) {
    if (!this.eventCache[name]) {
      this.eventCache[name] = []
    }

    this.eventCache[name] = this.eventCache[name].filter((obj) => {
      return obj.id !== id
    })
  }

  static emit(name: string, ...args: any[]) {
    if (!this.eventCache[name]) {
      console.warn('No event handlers for: ', name)
      return
    }

    this.eventCache[name].forEach((handler) => {
      handler.func(...args)
    })
  }
}

import { Tag } from './Tag'

import config from "../server-config"

export class Promotion extends Tag {
  public static publicPromotionUUIDs = [
    '2b9ac60e-6866-54e8-a3e2-333112fae39e', // Circuito Spa
    'a063b6fd-33cf-59f7-b8fc-1540286ecc3d', // Spa y Masaje
    'f19e817d-b538-5dad-841e-447725da90e2', // Spa & Gourmet
    'fb6d593a-616e-5538-8f34-ca1e7f56bc48', // Spa Privado
    'f43db24f-d6f4-5811-845f-7d6d9924fc0a', // Spa para Niños
    'bdef4677-d6b6-5a3f-afb9-fd192c093110', // Spa en Pareja
    'be293ba1-21df-596b-b7ad-4a180cbf3673', // Daypass
    'df48e2be-1f59-5543-a85c-38a21e90eb72', // Spa & Ritual
    '4fedbd81-6f87-5132-9024-38cf054bbf28', // Masaje en Pareja
    '2de11ff0-f646-596b-b672-0c67ab547eb6', // Spas para embarazadas
    '7d73fbe5-1a6a-5cf4-b3a5-e25ff41f10f5', // Spas para bebés
    '997e67f6-b7b6-51fd-9506-b48c9a556ec0', // Tratamientos para él
    'bbed10b0-c8dd-5bfc-91f2-70ea67185500', // Spas para grupos
    'cde43307-3499-5524-9e4e-19f3b1756501', // Spa nocturno
    'd79a6c20-899d-5564-a184-b69d8783dc11', // Spa privado
    'f52ae880-b08d-55c6-932e-c1c412361a55', // Spa cumpleaños
  ]

  public static isValentinesForTheGivenDate(date: Date): boolean {
    const diff = 20 * 24 * 60 * 60 * 1000
    const year = date.getFullYear()

    const endValentineDay = new Date(`${year}-02-15`)
    const startValentineDay = new Date(endValentineDay.getTime() - diff)

    return date.getTime() <= endValentineDay.getTime() && date.getTime() > startValentineDay.getTime()
  }

  public static isOther(promotionTag: Promotion) {
    return promotionTag.uuid === this.getOther().uuid
  }

  public static isCouple(promotionTag: Promotion) {
    if (promotionTag.uuid === this.getSpaForCouples().uuid) {
      return true
    }

    // PromotionTagKey.COUPLE_MASSAGE
    if (promotionTag.uuid === '4fedbd81-6f87-5132-9024-38cf054bbf28') {
      return true
    }

    return false
  }

  public static isChristmas(self: Promotion) {
    if (self.uuid === this.getChristmasSpecial().uuid) {
      return true
    }

    if (self.uuid === this.getChristmas().uuid) {
      return true
    }

    // Circuito Navidad
    if (self.uuid === '57e8d3bd-cdd7-5d16-8e02-aebe427d080a') {
      return true
    }

    // Circuito y Masaje Navidad
    if (self.uuid === '73897aaf-6752-5bdf-8fa8-dc816555078f') {
      return true
    }

    // Circuito y Ritual Navidad
    if (self.uuid === 'd2487808-4bff-5622-9a1b-576986d3b50b') {
      return true
    }

    // Circuito Spa y Comida Navidad
    if (self.uuid === '236b9397-b3c2-5e08-bfe0-52aa6f306a02') {
      return true
    }

    return false
  }

  public static isFathersDay(self: Promotion) {
    if (self.uuid == this.getFathersDay().uuid) {
      return true
    }

    return false
  }

  public static isMothersDay(self: Promotion) {
    if (self.uuid == this.getMothersDay().uuid) {
      return true
    }

    return false
  }

  public static isValentines(self: Promotion) {
    if (self.uuid == this.getValentines().uuid) {
      return true
    }

    return false
  }

  public static filterByActiveFest(promos: Array<Tag>): Array<Tag> {
    return promos.filter((tag) => {
      if (this.getFathersDay().uuid === tag.uuid) {
        return config.promos.isFathersDay
      }

      if (this.getMothersDay().uuid === tag.uuid) {
        return config.promos.isMothersDay
      }

      if (this.isChristmas(tag)) {
        return config.promos.isChrismas
      }

      if (this.getBlackFriday().uuid === tag.uuid) {
        return config.promos.isBlackFriday
      }

      if (this.getValentines().uuid === tag.uuid) {
        return config.promos.isValentines
      }

      if (this.isInternal(tag)) {
        return false
      }

      return true
    })
  }

  public static filterByNoFest(promos: Array<Tag>): Array<Tag> {
    return promos.filter((tag) => {
      if (this.getFathersDay().uuid === tag.uuid) {
        return false
      }

      if (this.getMothersDay().uuid === tag.uuid) {
        return false
      }

      if (this.isChristmas(tag)) {
        return false
      }

      if (this.getBlackFriday().uuid === tag.uuid) {
        return false
      }

      if (this.getValentines().uuid === tag.uuid) {
        return false
      }

      if (this.isInternal(tag)) {
        return false
      }

      return true
    })
  }

  public static isInternal(tag: Tag): boolean {
    if (this.isOther(tag)) {
      return true
    }

    if (this.getDiscount().uuid === tag.uuid) {
      return true
    }

    // Recomendados
    if (tag.uuid === '8b496fdd-8500-54d6-b156-c43756d8b586') {
      return true
    }

    return false
  }

  public static getOther() {
    return Promotion.from({
      uuid: 'other',
      title: 'Other', slug: 'other', description: '',
      translations: [
        {
          lang_code: 'es',
          title: 'otros',
          description: '',
          slug: '',
        },
        {
          lang_code: 'pt',
          title: 'outros',
          description: '',
          slug: '',
        },
      ],
    })
  }

  public static getFathersDay() {
    return Promotion.from({
      uuid: '724a498c-8cd8-5983-b762-ef9ea4a16180',
      title: 'Día del padre',
      slug: '', description: 'Día del padre'
    })
  }

  public static getMothersDay() {
    return Promotion.from({
      uuid: 'df14b029-070c-5981-950b-aab2f31ed881',
      title: 'Día de la Madre',
      slug: '', description: 'Día de la Madre'
    })
  }

  public static getBlackFriday() {
    return Promotion.from({
      uuid: 'cca4903a-dbb4-58ca-812c-7908e7bd455e',
      title: 'Black Friday',
      slug: '', description: 'Black Friday'
    })
  }

  public static getChristmasSpecial() {
    return Promotion.from({
      uuid: 'c55ccfee-3689-5b0c-ba6a-a8d8e76fef8e',
      title: 'Especial Navidad',
      slug: '', description: 'Especial Navidad'
    })
  }

  public static getChristmas() {
    return Promotion.from({
      uuid: '75244aed-fd34-5467-a879-946ec6e17c19',
      title: 'Navidad',
      slug: '', description: 'Navidad'
    })
  }

  public static getDiscount() {
    return Promotion.from({
      uuid: '0baee1b7-0f76-4c3e-a227-63ca39f96379',
      title: 'Descuentos',
      slug: '', description: 'Descuentos'
    })
  }

  // {"wordpress": {"id": 175}}
  public static getValentines() {
    return Promotion.from({
      uuid: 'e1f24baf-3b6a-5442-8155-da60d61ce3bd',
      title: 'San Valentín',
      slug: '', description: 'San Valentín'
    })
  }

  public static getSpaForCouples() {
    return Promotion.from({
      uuid: 'bdef4677-d6b6-5a3f-afb9-fd192c093110',
      title: 'Spa en Pareja',
      slug: '', description: 'Spa en Pareja'
    })
  }

  // EXTRA PROMOTIONS
  public static getTeacherGift() {
    return Promotion.from({
      uuid: '373a5e56-d748-4f8b-ac82-0e4a52bd0c69',
      title: 'Regalos para profesores',
      description: 'Regalos para profesores',
      slug: 'regalos-profesores',
    })
  }

  public static filterByPublicPromotions(promotionList: Array<Promotion>): Array<Promotion> {
    // Promociones/Tags Públicos

    return promotionList.filter((promo) => {
      return this.publicPromotionUUIDs.includes(promo.uuid)
    })
  }
}

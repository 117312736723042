<template>
  <div />
</template>

<script lang="ts">
import PlausibleAnaliticsEventBus from '~/core/events/plausible'
import CookiesRepository from '~/core/ts/repository/CookiesRepository'

type PlausibleEvent = {
  revenue: {
    amount: number,
    currency: 'EUR',
  } | undefined,
  props: {
    purchase_id: string,
    voucher_id: string,
  }
}

export default defineNuxtComponent({
  setup() {
    PlausibleAnaliticsEventBus.on((name: string, meta: PlausibleEvent) => {
      const klaroUserConfig = CookiesRepository.getCookiesAsObject('klaro')
      // if (!klaroUserConfig['plausible']) {
      if (!klaroUserConfig['google-analytics']) {
        console.warn('User declined Plausible Analitics')

        // NOTE: Esto no puede ser muy legal.
        // return
      }

      const win = window as any

      if (!win.plausible) {
        console.warn('Plausible is not enabled')
        win.plausible = win.plausible || function() { (win.plausible.q = win.plausible.q || []).push(arguments) }
      }

      win.plausible(name, meta)

      const blockers = CookiesRepository.getCookiesAsObject('blockers')
      if (blockers.enabled) {
        const { purchase_id, voucher_id } = meta.props
        if (!purchase_id) {
          return
        }

        const cookiesEnabled = !!klaroUserConfig['google-analytics']

        const adblockEvent = {
          name,
          purchase_id,
          voucher_id,

          cookies_accepted: cookiesEnabled,
          adblock_enabled: true,
        }

        win.plausible('Event with adblock', adblockEvent)
      }
    })

    return {}
  },
})
</script>

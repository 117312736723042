export class Money {
  constructor(
    public amount: number,
    public currency: string,
  ) {}

  static from(other: Money) {
    return new Money(other.amount, other.currency)
  }

  static fromAmount(amount: number) {
    return new Money(amount, 'EUR')
  }

  static compareWith(self: Money, other: Money): number {
    return self.amount - other.amount
  }

  static multiplyBy(self: Money, scalar: number): Money {
    return {
      amount: self.amount * scalar,
      currency: self.currency
    }
  }

  static toString(self: Money, decimals = 2): string {
    const { amount, currency } = self

    const formatter = Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: currency ?? 'EUR',
      useGrouping: true,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    const UTF16Space = '\xA0'
    return formatter.format(amount / 100).replace(UTF16Space, ' ').trim()
  }
}

<template>
  <div />
</template>

<script lang="ts">
import GA4AnaliticsEventBus from '~/core/events/ga4-analitics'
import CookiesRepository from '~/core/ts/repository/CookiesRepository'
import LoggerAPIRepository from '~/core/ts/repository/shared/LoggerAPIRepository'

export default defineNuxtComponent({
  setup() {
    const shouldSend = ref(false)

    onMounted(() => {
      GA4AnaliticsEventBus.on((name: string, meta: Record<string, string | object>) => {
        if (!shouldSend.value) { return }

        const blockers = CookiesRepository.getCookiesAsObject('blockers')
        meta.client_meta = { adblock: blockers.enabled }

        LoggerAPIRepository.sendInfo(name, meta).catch(() => {
          console.warn('Logger failed. Disable logger.')
          shouldSend.value = false
        })
      })
    })

    return {}
  }
})
</script>

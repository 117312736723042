import EventBus from "./event-bus"

export default class LoadingEventBus {
  static on(func: any): number {
    return EventBus.on('loading', func)
  }

  static off(id: number) {
    return EventBus.off('loading', id)
  }


  static show() {
    EventBus.emit('loading', true)
  }

  static hide() {
    EventBus.emit('loading', false)
  }
}

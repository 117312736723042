import { dateToDDMMYYY, dateToDDMMYYYWithTime } from "../util/date"
import { GetAway, type GetAwayDTO } from "./GetAway"
import { Money } from "./Money"
import { Picture, type PictureDTO } from "./Picture"

export type GetAwayRateMetadataDTO = {
  booking_procedure: {
    search: string,
    quote: string,
    book: string,
    occupancies: number[],
    context: string,
    supplier: {
      id: number,
      context: string,
    }
  },
}

export type GetAwayRateDTO = {
  type: string,
  name: string,
  description: string,
  basic_price: {
    amount: number,
    currency: string,
  },
  basic_price_with_discount: {
    amount: number,
    currency: string,
  },
  room_type: string,
  room_name: string,
  cancel_policy: {
    refundable: boolean,
    cancel_penalties: Array<{
      deadline: {
        timestamp: number,
        timezone: string,
      },
      hours_before: number,
      price: {
        amount: number,
        currency: string,
      },
      // metadata
    }>,
  },
  boardtype_type: string,
  metadata: GetAwayRateMetadataDTO,
}

export class GetAwayRate {
  constructor(
    public type: string,
    public name: string,
    public description: string,
    public basePrice: Money,
    public basePriceWithDiscount: Money,
    public cancelPolicy: {
      refundable: boolean,
      penalties: Array<{
        deadline: Date,
        hoursBefore: number,
        price: Money,
      }>,
    },
    public room: {
      type: string,
      name: string,
    },
    public boardtype: {
      type: string,
    },
    public metadata: {
      occupancies: number,
      dto: GetAwayRateMetadataDTO
    }
  ) {}

  public static from(dto: GetAwayRateDTO): GetAwayRate {
    const cancelPolicy = {
      refundable: dto.cancel_policy.refundable,
      penalties: dto.cancel_policy.cancel_penalties.map((penaltieDto) => {
        const deadline = new Date (penaltieDto.deadline.timestamp * 1000)
        const price = Money.from(penaltieDto.price)
        const hoursBefore = penaltieDto.hours_before

        return {
          deadline,
          hoursBefore,
          price
        }
      })
    }

    const room = {
      name: dto.room_name,
      type: dto.room_type,
    }

    const boardtype = {
      type: dto.boardtype_type,
    }

    const metadata = {
      occupancies: dto.metadata.booking_procedure.occupancies.length,
      dto: dto.metadata,
    }

    return new GetAwayRate(
      dto.type,
      dto.name,
      dto.description,
      Money.from(dto.basic_price),
      Money.from(dto.basic_price_with_discount),
      cancelPolicy,
      room,
      boardtype,
      metadata,
    )
  }

  private static getSortedCancelPenalties(self: GetAwayRate) {
    const sortedPenalties = self.cancelPolicy.penalties
      .map((a) => a)
      .sort((a, b) => {
        return a.deadline.getMilliseconds() - b.deadline.getMilliseconds()
      })

    if (!sortedPenalties.length) {
      return []
    }

    return sortedPenalties
  }

  public static isRefundable(self: GetAwayRate) {
    return self.cancelPolicy.refundable
  }

  public static getDeadlineDate(self: GetAwayRate): null | Date {
    if (!this.isRefundable(self)) {
      return null
    }

    const sortedPenalties = this.getSortedCancelPenalties(self)
    if (!sortedPenalties.length) {
      return null
    }
    const { deadline } = sortedPenalties[0]

    return deadline
  }

  public static getDeadlinePrice(self: GetAwayRate): null | Money {
    if (!this.isRefundable(self)) {
      return null
    }

    const sortedPenalties = this.getSortedCancelPenalties(self)
    if (!sortedPenalties.length) {
      return null
    }
    const { price } = sortedPenalties[0]

    return price
  }

  public static getRefundsLabel(self: GetAwayRate) {
    if (!this.isRefundable(self)) {
      return `No reembolsable. No se permiten cancelaciones ni cambios de fechas.`
    }

    const sortedPenalties = this.getSortedCancelPenalties(self)
    if (!sortedPenalties.length) {
      return ''
    }
    const { deadline, price } = sortedPenalties[0]

    const deadlineStr = dateToDDMMYYY(deadline)
    const priceStr = Money.toString(price, 2)

    return `Cancelación gratuita hasta el ${deadlineStr}, después tendrá un coste de ${priceStr}`
  }

  public static getRefundsLabelWithTime(self: GetAwayRate) {
    if (!this.isRefundable(self)) {
      return `No reembolsable. No se permiten cancelaciones ni cambios de fechas.`
    }

    const sortedPenalties = this.getSortedCancelPenalties(self)
    if (!sortedPenalties.length) {
      return ''
    }
    const { deadline, price } = sortedPenalties[0]

    const deadlineStr = dateToDDMMYYYWithTime(deadline)
    const priceStr = Money.toString(price, 2)

    return `Cancelación gratuita hasta las ${deadlineStr}, después tendrá un coste de ${priceStr}`
  }

  public static getRefundsLabelShort(self: GetAwayRate): string {
    if (!this.isRefundable(self)) {
      return 'No reembolsable'
    }

    const sortedPenalties = this.getSortedCancelPenalties(self)
    if (!sortedPenalties.length) {
      return ''
    }

    const { hoursBefore } = sortedPenalties[0]

    const DAY_HOURS = 24
    if (hoursBefore > DAY_HOURS * 2) {
      return `Cancelación gratuita hasta ${Math.ceil(hoursBefore / DAY_HOURS)} días antes`
    }

    return `Cancelación gratuita hasta ${hoursBefore} horas antes`
  }
}

// Se usa al inicar una compra de una Escapada.
export type GetAwayRateDTOMinified = {
  type: string,
  name: string,
  description: string,
  price: {
    amount: number,
    currency: string,
  },
  boardtype_type: string,
  room_type: string,
  room_name: string,
  metadata: {
    booking_procedure: {
      supplier: GetAwayRateMetadataDTO['booking_procedure']['supplier']
    }
  }
}

export class GetAwayRateMinified {
  constructor(
    public type: string,
    public name: string,
    public description: string,
    public price: {
      amount: number,
      currency: string,
    },
    public room: {
      type: string,
      name: string,
    },
    public boardtype: {
      type: string,
    },
    public metadata: {
      bookingProcedure: {
        supplier: GetAwayRateMetadataDTO['booking_procedure']['supplier']
      }
    }
  ) {}

  static from (rate: GetAwayRate): GetAwayRateMinified {
    const metadata = {
      bookingProcedure: {
        supplier: rate.metadata.dto.booking_procedure.supplier
      }
    }

    return new GetAwayRateMinified(
      rate.type,
      rate.name,
      rate.description,
      rate.basePriceWithDiscount,
      rate.room,
      rate.boardtype,
      metadata,
    )
  }

  static to(rate: GetAwayRateMinified): GetAwayRateDTOMinified {
    const metadata = {
      booking_procedure: rate.metadata.bookingProcedure
    }

    return {
      type: rate.type,
      name: rate.name,
      description: rate.description,
      price: rate.price,
      room_type: rate.room.type,
      room_name: rate.room.name,
      boardtype_type: rate.boardtype.type,
      metadata,
    }
  }
}

export type GetAwayAvailabilityDTO = {
  package_uuid?: string,
  package: GetAwayDTO,
  from: number,
  to: number,
  num_pax: number,
  rates: Array<GetAwayRateDTO>,
  total_nights: number,
  best_rate: GetAwayRateDTO | null,
  boardtypes_available: Array<{
    type: string,
    name: string,
    description: string,
    images: Array<PictureDTO>,
  }>,
  rooms_available: Array<{
    uuid: string,
    hotel_uuid_revision: string,
    name: string,
    descriptions: Array<{
      langcode: string,
      name: string,
      description: string,
    }>,
    type: string,
  }>,
}

export type GetAwayAvailabilityBoardtype = {
  type: string,
  name: string,
  description: string,
  images: Array<Picture>,
}

export type GetAwayAvailabilityRoom = {
  uuid: string,
  name: string,
  type: string,
}

export class GetAwayAvailability {
  constructor(
    public getAwayUUID: string,
    public getAway: GetAway,
    public from: number,
    public to: number,
    public rates: Array<GetAwayRate>,
    public bestRate: GetAwayRate | null,
    public boardtypesAvailable: Array<GetAwayAvailabilityBoardtype>,
    public roomsAvailable: Array<GetAwayAvailabilityRoom>,
    public numPax: number,
    public totalNights: number,
  ) {}

  public static from(dto: GetAwayAvailabilityDTO): GetAwayAvailability {
    const rates = dto.rates.map(GetAwayRate.from)
    const bestRate = dto.best_rate ? GetAwayRate.from(dto.best_rate) : null

    const boardtypesAvailable = dto.boardtypes_available.map((boardDto) => {
      return {
        type: boardDto.type,
        name: boardDto.name,
        description: boardDto.description,
        images: boardDto.images.map(Picture.from)
      }
    })

    const roomsAvailable = dto.rooms_available.map((roomDto) => {
      return {
        uuid: roomDto.uuid,
        name: roomDto.name,
        type: roomDto.type,
      }
    })

    return new GetAwayAvailability(
      dto.package.uuid,
      GetAway.from(dto.package),
      dto.from,
      dto.to,
      rates,
      bestRate,
      boardtypesAvailable,
      roomsAvailable,
      dto.num_pax,
      dto.total_nights,
    )
  }
}

export class GetAwayAvailabilityMinified {
  constructor(
    public packageUuid: string,
    public packageUuidRevision: string,
    public from: number,
    public to: number,
    public numPax: number,
  ) {}

  static from(availability: GetAwayAvailability): GetAwayAvailabilityMinified {
    return new GetAwayAvailabilityMinified(
      availability.getAwayUUID,
      availability.getAway.uuidRevision,
      availability.from,
      availability.to,
      availability.numPax,
    )
  }
}

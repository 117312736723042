<template>
  <div />
</template>

<script lang="ts">
import CookiesRepository from '~/core/ts/repository/CookiesRepository'
import type { VoidBooleanFunction } from '~/core/ts/util/function'

export default defineNuxtComponent({
  setup() {
    onMounted(() => {
      const requestURL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

      const checkAdsBlocked = (callback: VoidBooleanFunction) => {
        fetch(requestURL, {
          method: 'HEAD',
          mode: 'no-cors'
        })
          .then((response) => {
            callback(response.redirected)  // ads are blocked if request is redirected
          })                                // (we assume the REQUEST_URL doesn't use redirections)
          .catch(() => {
            callback(true)                 // ads are blocked if request fails
          })                               // (we do not consider connction problems)
      }

      checkAdsBlocked((adsBlocked: boolean) => {
        CookiesRepository.pushCookie('blockers', 'enabled', adsBlocked)
      })
    })

    return {}
  },
})
</script>

import EventBus from "./event-bus"

import { translateByKeySilent } from "../ts/util/translate"

export default class NotificationEventBus {
  static on(func: any): number {
    return EventBus.on('notification', func)
  }

  static off(id: number) {
    return EventBus.off('notification', id)
  }

  static show(title: string, message: string) {
    const transTitle = translateByKeySilent(title) || title
    const transMessa = translateByKeySilent(message) || message
    EventBus.emit('notification', transTitle, transMessa)
  }
}

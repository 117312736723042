<template>
  <div>
    <GeneralGA4Analitics />
    <!-- <GeneralPlausibleAnalitics /> -->
    <GeneralLoading />
    <GeneralNotificator />
    <GeneralLogger />
    <GeneralAdBlockDetector />

    <GeneralChat />
    <Sentry />

    <!-- TODO: Cambiar esto por un nuxt-page para que los layout vayan dentro de cada página y así poder pasarles parámetros -->
    <nuxt-layout />
  </div>
</template>

<script lang="ts">
import GeneralGA4Analitics from '~/core/components/singleton/GeneralGA4Analitics.vue'
import GeneralPlausibleAnalitics from './core/components/singleton/GeneralPlausibleAnalitics.vue'
import GeneralLoading from '~/core/components/singleton/GeneralLoading.vue'
import GeneralNotificator from '~/core/components/singleton/GeneralNotificator.vue'
import GeneralLogger from './core/components/singleton/GeneralLogger.vue'
import GeneralAdBlockDetector from './core/components/singleton/GeneralAdBlockDetector.vue'

import GeneralChat from './core/components/singleton/GeneralChat.vue'
import Sentry from '~/core/components/singleton/Sentry.vue'

export default defineNuxtComponent({
  components: {
    GeneralGA4Analitics,
    GeneralPlausibleAnalitics,
    GeneralLoading,
    GeneralNotificator,
    GeneralLogger,
    GeneralAdBlockDetector,
    GeneralChat,
    Sentry,
  },
})
</script>

<style lang="scss">
// Util SCSS clases
.util-snippet {
  @apply h-0;
  @apply opacity-0;
  display: none;
}

.only-mobile {
  @screen lg {
    @apply hidden;
  }
}

.only-desktop-block {
  @apply hidden;
  @screen lg {
    @apply block;
  }
}

.cm-body {
  display: none;
}

// .cn-decline, .cm-btn-decline {
//   display: none !important;
// }

.cm-btn-accept {
  // display: none;
  background-color: #557f9c !important;
  color: white;
}

.cm-btn-accept-all {
  background: #4d9907 !important;
  color: white;
}

.cm-powered-by a {
  display: hidden !important;
}

.cm-footer-buttons {
  justify-content: end !important;
}

</style>

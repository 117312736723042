<template>
  <div
    :role="role"
    class="card"
    :class="{
      'animate-hover': animateHover,
      'mobile-with-border': mobileWithBorder,
      'desktop-with-border': desktopWithBorder,
      'transparent': transparent,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    role: {
      type: String,
      default: '',
    },
    animateHover: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    desktopWithBorder: {
      type: Boolean,
      default: false,
    },
    mobileWithBorder: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss">
.card {
  @apply p-1;
  @apply rounded-4xl;
  @apply border-0;

  transition: box-shadow 0.5s;
  transition: border-color 0.5s;

  @apply bg-white;

  @screen lg {
    @apply border-0;
  }
}

.card.transparent {
  @apply bg-transparent;
}

.card.mobile-with-border {
  @apply border-2;
  @apply border-spl-gray-1;
  @screen lg {
    @apply border-0;
  }
}

.card.desktop-with-border {
  @screen lg {
    @apply border-2;
    @apply border-spl-gray-1;
  }
}

.card.mobile-with-border.animate-hover:hover {
  @apply border-spl-gray-1;
}

.card.desktop-with-border.animate-hover:hover {
  @screen lg {
    @apply border-spl-gray-1;
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.2);
  }
}
</style>

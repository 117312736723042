import revive_payload_client_4sVQNw7RlN from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import click_outside_D2NiLGp7dJ from "/usr/src/app/content/n3-spas-4ef0a1b3-0f28-49c0-8941-2dbaa884192a/b2c-build/plugins/click-outside.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  click_outside_D2NiLGp7dJ
]
import { lang as LANG, supportedLangs, getCurrentLanguage } from '../lang-config'
import { joinPaths, removePartFromURL, slashUri, urlHasPart } from './string'

export type LangCode = 'es' | 'pt'

export function getLanguageFromURL(url: string): string {
  let lang = 'es'

  for (const langCode of supportedLangs) {
    if (urlHasPart(url, langCode)) {
      lang = langCode
    }
  }

  return lang
}

export function addLanguageToURL(url: string, lang: string): string {
  const cleanedURI = removeAnyLangFromURL(url)

  if (lang && lang !== 'es') {
    return slashUri(joinPaths(lang, cleanedURI))
  }

  return slashUri(cleanedURI)
}

export function removeAnyLangFromURL(url: string): string {
  let final = url

  for (const langCode of supportedLangs) {
    final = removePartFromURL(final, langCode)
  }

  return final
}

export function removeCurrentLangFromURL(url: string): string {
  const currentLang = getCurrentLanguage()
  return removePartFromURL(url, currentLang)
}

export function translate<T>(dict: Record<string, T>): T {
  const trans = translateSilent<T>(dict)
  if (trans === null) {
    const lang = getCurrentLanguage()
    throw new Error(`ERROR: translateForLang missing language: "${lang}" in: "${dict}"`)
  }

  return trans
}

export function translateSilent<T>(dict: Record<string, T>): T | null {
  const lang = getCurrentLanguage()
  return translateForLangSilent(dict, lang)
}

export function translateForLang<T>(dict: Record<string, T>, lang: string): T {
  const trans = translateForLangSilent(dict, lang)
  if (trans === null) {
    throw new Error(`ERROR: translateForLang missing language: "${lang}" in: "${dict}"`)
  }
  return trans
}

export function translateForLangSilent<T>(dict: Record<string, T>, lang: string): T | null {
  const defaultLang = 'es'

  const translated = dict[lang]

  if (translated) {
    return translated
  }

  // Traducción por defecto: 'es'
  if (!translated && dict[defaultLang]) {
    return dict[defaultLang]
  }

  // Si no está disponible ni la que se pide por parámetro ni la traducción en 'es'
  //   se retorna la primera que encontremos, si existe alguna.
  const availableValues = Object.values(dict)
  if (!availableValues.length) {
    return null
  }

  return availableValues[0]
}

export function translateByKey(key: string, plural = false): string {
  const trans = translateByKeySilent(key, plural)
  if (trans === null) {
    const language = getCurrentLanguage()
    throw new Error(`ERROR: translateByKey missing key: "${key}" language: "${language}"`)
  }

  return trans
}

export function translateByKeySilent(key: string, plural = false): string | null {
  const lang = getCurrentLanguage()
  return translateByKeySilentForLang(key, lang, plural)
}

export function translateByKeyForLang(key: string, lang: string, plural = false): string {
  const trans = translateByKeySilentForLang(key, lang, plural)
  if (trans === null) {
    throw new Error(`ERROR: translateByKey missing key: "${key}" language: "${lang}"`)
  }

  return trans
}

export function translateByKeySilentForLang(key: string, langCode: string, plural = false): string | null {
  const langDict = translateForLang(LANG, langCode)

  // Plural activo: Si tenemos plural, lo usamos.
  if (plural) {
    const phrase = langDict.plural[key]
    if (phrase) {
      return phrase
    }
  }

  const phrase = langDict.singular[key]
  if (phrase) {
    // Plural activo: sin plural disponible, singular con 's'
    if (plural) {
      return `${phrase}s`
    }
    return phrase
  }

  return null
}

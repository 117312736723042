<template>
  <div>
    <Loading
      v-if="display"
      :is-displayed="display"
    />
  </div>
</template>

<script lang="ts">
import LoadingEventBus from '~/core/events/loading'
import Loading from '@/core/components/shared/Loading.vue'

export default defineNuxtComponent({
  components: {
    Loading,
  },
  setup() {
    const display = ref(false)

    let id: null | number = null
    onBeforeMount(() => {
      id = LoadingEventBus.on((value: boolean) => {
        display.value = value
      })
    })

    onBeforeUnmount(() => {
      if (id) {
        LoadingEventBus.off(id)
      }
    })

    return {
      display,
    }
  }
})
</script>

import config from '../server-config'

import { capitalizeAllWords } from '../util/string'
import { translate } from '../util/translate'

import { Picture, type PictureDTO } from './Picture'
import { Service, type ServiceDTO } from './Service'
import type { SpaLocation } from './Spa'
import { Tag, type TagDTO } from './Tag'

export type GetAwayDTO = {
  uuid: string,
  uuid_revision: string,
  status: string,

  occupancy_resort: {
    hotel: {
      uuid: string,
      uuid_revision: string,
      name: string,
      slug: string,
      descriptions: Array<{
        langcode: string,
        description: string,
      }>,
      category: {
        type: string, // Eg. 4S
        name: string,
        value: number
      } | null,
      city: string,
      political_location_uuid: string,
      address: string,
      postal_code: string,
      country: string,
      latitude: number,
      longitude: number,
      contact: {
        email: string,
        web: string,
        phone: string
      },
      // Las escapadas tienen un array de habitaciones pero no se usa.
      // Las habitaciones disponibles se sacan de la info de disponibilidad.
      // rooms: [],
      images: Array<PictureDTO>,
    },
  },
  spa_resort: {
    spa: {
      uuid: string
      uuid_revision: string,
      brand_id: string,
      hotel_uuid: string,
      images: Array<PictureDTO>,

      name: string,

      city: string,
      address: string,
      postal_code: string,
      political_location_uuid: string,
      locality_uuids: Array<string>,
      latitude: number,
      longitude: number,

      translations: Array<{
        langcode: string,
        long_description: string,
        short_description: string,
        legal_conditions: {
          legal_notice: string,
          political_condition:  string,
          general_condition: string,
        }
      }>

      rating: {
        value: number | null;
      } | null;

      // NOTE: faltan campos que no son necesarios justo ahora.

    }
    services: Array<ServiceDTO>,
  }
  slug: string,
  uri: string,

  translations: Array<{
    langcode: string,
    name: string,
    short_name: string | undefined,
    description: string,
    slug: string,
    uri: string,
  }>,
  images: Array<PictureDTO>,

  amenities: Array<TagDTO>,
  facilities: Array<TagDTO>,
}

function concatenateHotelLiteralIfNeeded(originalName: string): string {
  if (!originalName) {
    return originalName
  }

  const lowercase = originalName.toLowerCase()

  if (lowercase.includes('hotel') || lowercase.includes('resort') || lowercase.includes('apartamento')) {
    return originalName
  } else {
    return `Hotel ${originalName}`
  }
}

function getLocation(dto: GetAwayDTO): SpaLocation {
  if (dto.spa_resort && dto.spa_resort.spa) {
    return {
      address: dto.spa_resort.spa.address,
      state: dto.spa_resort.spa.city,
      zipCode: dto.spa_resort.spa.postal_code,
      country: 'es',
      coords: {
        latitude: dto.spa_resort.spa.latitude,
        longitude: dto.spa_resort.spa.longitude,
      },
    }
  }

  return {
    address: dto.occupancy_resort.hotel.address,
    state: dto.occupancy_resort.hotel.city,
    zipCode: dto.occupancy_resort.hotel.postal_code,
    country: 'es',
    coords: {
      latitude: dto.occupancy_resort.hotel.latitude,
      longitude: dto.occupancy_resort.hotel.longitude,
    },
  }
}

export type GetAwayHotel = {
  uuid: string,
  name: string,
  category: {
    type: string,
    name: string,
    value: number,
  } | null,
  politicalLocationUUID: string,
  images: Array<Picture>,
  texts: Record<string, { description: string }>,
}

export type GetAwaySpa = {
  uuid: string,
  name: string,
  score: number,
  location: SpaLocation,
  texts: Record<string, { shortDescription: string, longDescription: string }>,
  images: Array<Picture>,
  services: Array<Service>,
}

export type GetAwayTexts = {
  name: string,
  shortName: string,
  description: string,
  slug: string,
  uri: string,
}

export class GetAway {
  // NOTE: Faltan campos que convertir, pero no son necesarios ahora.
  constructor(
    public uuid: string,
    public uuidRevision: string,
    public status: 'active' | 'inactive',
    public slug: string,
    public uri: string,
    public includesSpa: boolean,
    public hotel: GetAwayHotel,
    public images: Array<Picture>,
    public spa: GetAwaySpa,
    public texts: Record<string, GetAwayTexts>,
    public amenities: Array<Tag>,
    public facilities: Array<Tag>,
  ) {
  }

  static from(dto: GetAwayDTO): GetAway {
    const hotelTexts: Record<string, { description: string }> = {}

    dto.occupancy_resort.hotel.descriptions.forEach((trDto) => {
      hotelTexts[trDto.langcode] = { description: trDto.description }
    })

    const hotelName = capitalizeAllWords(
      concatenateHotelLiteralIfNeeded(
        dto.occupancy_resort.hotel.name
      )
    )

    const hotel = {
      uuid: dto.occupancy_resort.hotel.uuid,
      name: hotelName,
      category: dto.occupancy_resort.hotel.category,
      politicalLocationUUID: dto.occupancy_resort.hotel.political_location_uuid,
      images: dto.occupancy_resort.hotel.images.map(Picture.from),
      texts: hotelTexts,
    }

    const spatexts: GetAwaySpa['texts'] = {}
    dto.spa_resort.spa.translations.forEach((trDto) => {
      spatexts[trDto.langcode] = {
        shortDescription: trDto.short_description,
        longDescription: trDto.long_description,
      }
    })
    const spa = {
      uuid: dto.spa_resort.spa.uuid,
      name: dto.spa_resort.spa.name,
      location: getLocation(dto),
      score: dto.spa_resort.spa.rating !== null ? Number(dto.spa_resort.spa.rating.value) : 0,
      texts: spatexts,
      images: dto.spa_resort.spa.images.map(Picture.from),
      services: dto.spa_resort.services.map(Service.from),
    }

    const texts: Record<string, GetAwayTexts> = {}
    dto.translations.forEach((trans) => {
      texts[trans.langcode] = {
        name: trans.name,
        shortName: trans.short_name || '',
        description: trans.description,
        slug: trans.slug,
        uri: trans.uri,
      }
    })

    //
    const IMAGES_MIN_COUNT = 5
    const images = dto.images.map(Picture.from).slice(0, IMAGES_MIN_COUNT)

    if (dto.occupancy_resort && dto.occupancy_resort.hotel) {
      const countToGetFromHotel = Math.max(IMAGES_MIN_COUNT - images.length, 0)
      dto.occupancy_resort.hotel.images
        .slice(0, countToGetFromHotel)
        .forEach((imageDto) => images.push(Picture.from(imageDto)))
    }

    if (dto.spa_resort && dto.spa_resort.spa) {
      const countToGetFromSpa = Math.max(IMAGES_MIN_COUNT - images.length, 0)
      dto.spa_resort.spa.images
        .slice(0, countToGetFromSpa)
        .forEach((imageDto) => images.push(Picture.from(imageDto)))
    }

    if (!images.length) {
      const picture = Picture.getDefaultImage()
      images.push(picture)
    }

    // Se coge la URL que tenga el idioma en el que se está generando la web o la que viene por defecto: 'ES'
    let uri = dto.uri
    dto.translations.forEach((trans) => {
      if (trans.langcode === config.runtime.language) {
        uri = trans.uri
      }
    })

    let includesSpa = false
    if (dto.spa_resort && dto.spa_resort.services) {
      includesSpa = !!dto.spa_resort.services.length
    }

    const amenities = dto.amenities.map(Tag.from)
    const facilities = dto.facilities.map(Tag.from)

    return new GetAway(
      dto.uuid,
      dto.uuid_revision,
      dto.status === 'active' ? 'active' : 'inactive',
      dto.slug,
      uri,
      includesSpa,
      hotel,
      images,
      spa,
      texts,
      amenities,
      facilities,
    )
  }

  static getTagUUIDs(self: GetAway): Array<string> {
    const uuids: Array<string> = []
    self.spa.services.forEach((service) => uuids.push(...service.tagUUIDs))
    return uuids
  }

  static hasTagUUID(self: GetAway, tagUUID: string): boolean {
    for (const service of self.spa.services) {
      for (const uuid of service.tagUUIDs) {
        if (tagUUID === uuid) {
          return true
        }
      }
    }

    return false
  }

  static getMiniDataImages(self: GetAway): Array<{image: Picture, name: string}> {
    const miniData: Array<{image: Picture, name: string}> = []

    // Habitación
    const roomImages = self.hotel.images.filter((img) => img.relationType === 'room')
    if (roomImages.length) {
      miniData.push({
        image: roomImages[0],
        name: 'Habitación para dos',
      })
    }

    if (self.spa.services.length) {
      const name = self.spa.services.map((service) => {
        return translate(service.texts).name
      }).join(' + ')
      const imageCollection: Array<Picture> = []
      self.spa.services
        .forEach(
          (service) => service.images.forEach((img) => imageCollection.push(img))
        )

      if (imageCollection.length) {
        miniData.push({
          image: imageCollection[0],
          name,
        })
      }
    }

    // Hotel
    const hotelImages = self.hotel.images.filter((img) => img.relationType === 'hotel')
    if (hotelImages.length) {
      miniData.push({
        image: hotelImages[0],
        name: 'Instalaciones',
      })
    }

    return miniData
  }

  public static normalizePaxNumber(paxNumber: number): number {
    if (paxNumber < 2) {
      return 2
    }

    return paxNumber + (paxNumber % 2)
  }
}

const months = [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic' ]

export function dateFromSecodsTimeStamp(utc: number): Date {
  return new Date(utc * 1000)
}

export function dateToYYYYMMDD(date: Date): string {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${month}-${day}`
}

export function dateToDDMMYYY(date: Date): string {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${day}-${month}-${year}`
}

export function dateToDDmmYYY(date: Date): string {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  const monthStr = months[month]

  return `${day}-${monthStr}-${year}`
}

export function dateToDDMMYYYWithTime(date: Date): string {
  const hours = date.getHours() <= 9 ? `0${date.getHours()}` : `${date.getHours()}`
  const minutes = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : `${date.getMinutes()}`

  return `${hours}:${minutes} del ${dateToDDMMYYY(date)}`
}

export function datetoDDmm(date: Date): string {
  const day = date.getDate()
  const month = date.getMonth()

  const monthStr = months[month]

  return `${day} ${monthStr}`
}

export function checkIfIsDate(
  value?: Date |null,
  errorMessage?: string
): asserts value is Date {
  if (!(value instanceof Date)) {
    throw new Error(errorMessage ?? `Value "${value}" is not an instance of Date`)
  }
}

export function ensureDateFromYYYYMMDD(dateStr: string): Date {
  const parts = dateStr.split(/[- :]/)
  if (parts.length < 3) {
    return new Date()
  }

  const [ yearStr, monthStr, dayStr ] = parts

  const today = new Date()

  const year = yearStr ? Number(yearStr) : today.getFullYear()
  const month = monthStr ? Number(monthStr) : 1
  const day = dayStr ? Number(dayStr) : 1

  return new Date(year, month - 1, day)
}

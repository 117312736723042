<template>
  <div />
</template>

<script lang="ts">
import * as Sentry from "@sentry/browser"

export default defineNuxtComponent({
  components: {

  },
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const versionTag = runtimeConfig.public.versionTag

    onMounted(() => {
      if (!runtimeConfig.public.sentryDSN) {
        console.warn('Sentry is disabled. No DSN provided')
        return
      }

      Sentry.init({
        dsn: runtimeConfig.public.sentryDSN,

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: 'web-b2c-n3@' + versionTag,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.25,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        ignoreErrors: [
          'jQuery is not defined',
          "Can't find variable: jQuery",
        ]
      })
    })

    return {}
  }
})
</script>
